header{
	background-color: transparent;
	height: 90px;

	@include media-breakpoint-down(md) {
		height: 70px;
	}
	@media (max-height: 700px) {
		height:70px;
	}
	.interior &{
		background-color: $blanco;
	}
	.planes.interior &{
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 11;
	}
	.centros.interior &{
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 10;
	}
	.logo{
		@include media-breakpoint-down(md) {
			width: 120px;
		}
		@media (max-height: 700px) {
			width:130px;
		}
		&.logo-rfa{
			filter: brightness(0);
		}
		&.logo-evo{
			width:110px;
			@include media-breakpoint-down(md) {
				width: 90px;
			}
		}
	}
	.logo-cliente{
		margin-top:20px;
	}
	.menu{
		padding:0;
		text-align: center;
		li{
			margin-left: 10px;
			list-style:none;
			display: inline-block;
			@include fontSize($body-size-s);
			a{
				color:#4c4b4b;
				&:hover{
					@extend .fuente-title;
					color:$negro;
					text-decoration: underline;
				}
			}
			&.active{
				@extend .fuente-title;
				a{

					text-decoration: none;
					color:$rosa;
				}
			}
		}
	}
}
.header-area{
	height:100px;
	background-color:$blanco;
	background: $blanco;
	border-bottom:4px solid $negro;
	z-index:11;
	.logo{
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-down(sm) {
			margin-right: unset !important;
			margin-left: 0px;
		}
		img{
			@include media-breakpoint-down(md) {
				width: 250px;
				height: auto;
			}
			@include media-breakpoint-down(sm) {
				width: 200px;
			}
			@include media-breakpoint-down(xs) {
				width: 125px;
			}
		}
	}
	.cont-menu{
		position:absolute;
		@include media-breakpoint-down(sm) {
			right:0px;
			top:-5px;
		}
		.menu{
			a{
				margin-right:13px;
				@include fontSize(20px);
				font-family: 'Gotham Medium';
				color:$negro;
				margin-left: 26px;
				text-transform: uppercase;
				@include media-breakpoint-down(md) {
					margin-right:15px;
					margin-left:0px;
				}
			}
		}
	}
	.cont-user-menu{
		.btn-user{
			background-color:transparent;
			&:after{
				background-image:url("../images/ico-arrow-down-azul.svg");
				width:14px;
				height:8px;
				background-size: cover;
				border: none;
				@include media-breakpoint-down(sm) {
					display:none;
				}
			}
			&:hover{
				background-color:transparent!important;
			}
			&:focus{
				border:none;
				box-shadow:none;
			}
			&:active{
				background-color:transparent!important;
			}
			img{
				@include media-breakpoint-down(xs) {
					width:30px;
				}
			}
		}
		.dropdown-menu{
			padding: 0px 0px;
			border:1px solid #C6C6C6;
			border-radius:0px;
			top: 85px!important;
			transform: translateX(-50%)!important;
			left: 135px!important;
			width:250px;
			@include media-breakpoint-down(sm) {
				top: 50px !important;
				right: unset;
				left:-35px !important;
			}
			a{
				@include fontSize(20px);
				font-family: 'Gotham Book';
				color:$negro;
				border-bottom:1px solid #C6C6C6;
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 10px;
				padding-top: 10px;
				&:last-child{
					border:none;
				}
				&:hover{
					background-color:#C6C6C6;
				}
			}
		}
	}
}
.header-cont-user{
	position: fixed;
	transform: translate(-50% , -50%);
	left: 50%;
	top: 40px;
	z-index: 11;
	pointer-events: none;
	@include media-breakpoint-down(sm) {
		padding-right: 65px;
	}
	span{
		margin-right: 15px;
		@include fontSize(20px);
		font-family: 'Gotham Medium';
		color: $negro;
		@include media-breakpoint-down(xs) {
			margin-right: 0px;
			margin-top: 20px;
		}
		&:before{
			content:"";
			background-image:url('../images/ico-creditos.svg');
			width:31px;
			height:32px;
			background-size: cover;
			display:inline-block;
			margin-right: 8px;
		}
	}
	a{
		pointer-events: all;
		background-color:$rosa;
		border-radius: 0px;
		line-height: 2.28em;
		text-decoration: none;
		padding: unset;
		height: 25px;
		@include media-breakpoint-down(xs) {
			width: 30px;
			height: 30px;
			top: 10px;
			right: -10px;
		}
		p{
			display: flex;
			padding: 0px 5px;
			@include media-breakpoint-down(xs) {
				margin-left: 0px;
				margin-top: 0px;
			}
			b{
				color:#E5007D;
				background-color:$blanco;
				border-radius: 100%;
				width: 15px;
				height: 15px;
				line-height: 1.3em !important;
				margin-right: 5px;
				display:block;
				margin-top: 15px;
				font-size: 12px;
				font-family: 'Gotham Light';
				line-height:1.4em;
				@media (max-height: 900px) {
					margin-top: 11px;
				}
				@include media-breakpoint-down(xs) {
					margin-top: 1px;
					margin-right: 0px;
					width: 25px;
					height: 25px;
					font-size: 21px;
				}
			}
			span{
				color: #fff;
				font-size: 12px;
				font-family: Gotham Medium;
				margin-right:5px;
				@include media-breakpoint-down(xs) {
					display:none;
				}
				&:before{
					display:none;
				}
			}
		}
	}
}

